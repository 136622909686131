import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setReduxRegimeData } from 'redux/Slices';
import { add, editIconY, uploaded } from 'res/images';
import { CommonAlert, Loader, Pagination } from 'whealth-core-web/components';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import {
  dateFormat,
  pdfData,
  profileImage,
  splitArrData,
  teamRoles,
  timeFormat,
} from 'whealth-core-web/components/Helper';

function RenderQuestionTab(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [behaviourQuetionData, setbehaviourQuetionData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const ApiClient = useApiManager();
  const { id, pdf } = useParams();
  const { newQuestions } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    getListData();
  }, [id, currentPage]);

  const getListData = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.behaviourQuestionList(id, params)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setbehaviourQuetionData(res.data);
        dispatch(setReduxRegimeData(res.data));
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPdf = (item) => {
    setIsLoading(true);
    ApiClient.questionPdfDownload(id, item.id)
      .then((res) => {
        setIsLoading(false);
        pdfData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const statusPublish = (item) => {
    setIsLoading(true);
    ApiClient.statusQuestionPublish(id, item.id)
      .then((res) => {
        setIsLoading(false);
        getListData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderAnswer = (item, key) => {
    if (item.behaviour_question.type == 'multi_select') {
      let ansArr = splitArrData(item[key], '|');
      return (
        <ul>
          {ansArr.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      );
    }
    if (item.behaviour_question.type == 'date') {
      const input = item[key];
      let [year, month, day] = typeof input == 'string' ? input.split('-') : '';
      return item[key] ? `${day}/${month}/${year}` : '';
    }
    return item[key];
  };

  const duplicateQuestions = (question_id) => {
    setIsLoading(true);
    ApiClient.duplicateRegimeQuestions(id, question_id)
      .then((res) => {
        getListData();
        setIsLoading(false);
        setIsShowAlert('Regime Report Duplicated Successfully.');
        setAlertType('alert-success');
        // navigate(`/patient/${id}/questions/${res.data.id}/edit`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const behaviouQuestionDelete = (questionId) => {
    setIsLoading(true);
    // seterror({});
    ApiClient.deleteQuestions(id, questionId)
      .then((res) => {
        setIsLoading(false);
        getListData();
        console.log(res);
        setIsShowAlert('Regime Report deleted successfully.');
        setAlertType('alert-success');
      })
      .catch((err) => {
        // setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };

  const getRegimePdf = () => {
    setIsLoading(true);
    ApiClient.regimePdfDownload(id)
      .then((res) => {
        setIsLoading(false);
        pdfData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderQuestionlist = (item, index) => {
    const questionsByCategory = organizeQuestionsByCategory(item.behaviour_questionnaire_answers);
    return (
      <div className='cardDataBorder w-100'>
        <div className='d-flex justify-content-between'>
          <div className='headingMilestone d-flex justify-content-between'>
            <div>
              <b>Assigned On &nbsp; &nbsp;</b>
              {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
            </div>
            <div className='d-block  ms-2 '>
              <span className={`badge bg-info  capitalize ${item.status}`}>{item.status}</span>
            </div>
          </div>

          <div className='memberShipDetails' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {item.status == 'draft' && (
              <div
                className='mb-1 ms-2 pointer'
                onClick={() => {
                  let windoConfirm = window.confirm('Are you sure you want to delete this regime report?');
                  if (windoConfirm) {
                    behaviouQuestionDelete(item.id);
                  }
                }}>
                <div className='pt-1'>
                  <CommonToolTip
                    placement={'top'}
                    item={<span class='material-icons-outlined'>delete_outline</span>}
                    showValue={'Delete'}
                  />
                </div>
              </div>
            )}

            <div
              className='mb-1 ms-2 pointer'
              onClick={() => {
                duplicateQuestions(item.id);
              }}>
              <CommonToolTip
                placement={'top'}
                item={
                  <div className='pt-1'>
                    <span className='material-icons-outlined' style={{ color: '#757575' }}>
                      content_copy
                    </span>
                  </div>
                }
                showValue={'Duplicate'}
              />
            </div>

            <>
              {item.status === 'published' && (
                <div className='mb-2 pointer' onClick={() => getPdf(item)}>
                  <CommonToolTip placement={'top'} item={<img src={uploaded} />} showValue={'Download Pdf'} />
                </div>
              )}

              {item.status === 'draft' && (
                <div
                  onClick={() => {
                    navigate(`/patient/${id}/questions/${item.id}/edit`);
                  }}
                  className='mb-2 ms-2 pointer'
                  style={{ marginLeft: '0px' }}>
                  <CommonToolTip
                    placement={'top'}
                    item={<img src={editIconY} className='icon' style={{ cursor: 'pointer' }} />}
                    showValue={'Edit'}
                  />
                </div>
              )}

              {item.status !== 'published' && (
                <div className='mb-2 ms-2 pointer' style={{ marginLeft: '0px' }}>
                  <CommonToolTip
                    placement={'top'}
                    item={
                      <div className='form-check form-switch toggleBtn pt-1' style={{ marginLeft: '0' }}>
                        <input
                          className='form-check-input'
                          style={{ cursor: 'pointer', marginLeft: '0' }}
                          checked={item.status === 'published'}
                          onChange={(e) => {
                            const windowConfirm = window.confirm(
                              `Are you sure you want to ${
                                item.status === 'published' ? 'unpublish' : 'publish'
                              } question?`
                            );
                            if (windowConfirm && item.status !== 'published') {
                              statusPublish(item);
                            }
                          }}
                          type='checkbox'
                          role='switch'
                        />
                      </div>
                    }
                    showValue={` ${item.status === 'published' ? 'Unpublish' : 'Publish'} `}
                  />
                </div>
              )}
            </>
          </div>
        </div>
        <div className='d-flex w-100 justify-content-between'>
          <div class='d-flex mt-1 mb-3'>
            <div class='me-2'>
              <img
                className='rounded coachImage float-start img-fluid'
                src={profileImage(item?.admin?.gender, item?.admin?.profile_url)}
              />
            </div>
            <div class=''>
              <h5 class='coachtext capitalize'>{item.admin ? item?.admin?.full_name : 'System'}</h5>
              <p class='zeefitness capitalize'>{teamRoles(item?.admin?.role)}</p>
            </div>
          </div>
        </div>
        {Object.keys(questionsByCategory).map((category, index) => (
          <div>
            {/* <div>
              <span>
                <b>Category : </b>
                {category}
              </span>
              <b className='capitalize'> {item.title} </b>
            </div> */}
            <div>
              <b>{item?.behaviour_question_category?.title ? 'Category :' : ''}</b>&nbsp;
              {item?.behaviour_question_category?.title}
            </div>
            <div className='table-responsive'>
              <table className='w-100 table'>
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th width='200'>Questions</th>
                    <th width='200'>Answers</th>
                    <th width='200'>Recommendations</th>
                    <th width='150'>Attached Goal</th>
                  </tr>
                </thead>
                <tbody>{renderCategoryQuestions(questionsByCategory[category])}</tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const organizeQuestionsByCategory = (questions) => {
    const questionsByCategory = {};
    questions.forEach((item) => {
      const category = item?.behaviour_question?.category?.title || 'Miscellaneous';

      if (!questionsByCategory[category]) {
        questionsByCategory[category] = [];
      }

      questionsByCategory[category].push(item);
    });

    return questionsByCategory;
  };

  const renderCategoryQuestions = (questions) => {
    return questions.map((item, index) => (
      <tr>
        <td>{index + 1}</td>
        <td>{item.behaviour_question.question}</td>
        <td>{renderAnswer(item, 'answer')}</td>
        <td>{renderAnswer(item, 'recommendation')}</td>
        <td>{item?.patient_goal?.goal?.title}</td>
      </tr>
    ));
  };

  return (
    <div>
      <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} />
      <div className='d-flex'>
        <div className='pt-3'>
          <button type='button' className='btn mt-3 btn-outline-primary' onClick={newQuestions}>
            <img src={add} className='icon' /> Add Report Card
          </button>
        </div>
        <div className='pt-3'>
          <button onClick={() => getRegimePdf()} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={uploaded} className='icon' /> Download Regime
          </button>
        </div>

        <Loader show={isLoading} />
      </div>

      {behaviourQuetionData.map((item, index) => {
        return renderQuestionlist(item, index);
      })}
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </div>
  );
}

export default RenderQuestionTab;
