import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import endPoints from 'networking/endPoints';
import auth_axios from 'networking/AuthAxios';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, body, params }) => {
    try {
      const result = await auth_axios({ url: baseUrl + url, method, data: body, params });
      return { data: result };
    } catch (err) {
      return { data: err.response };
    }
  };

export const dashboardSlice = createApi({
  reducerPath: 'dashboardData',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: ['dashboard'],

  endpoints: (builder) => ({
    getDashBoardData: builder.query({
      query: () => {
        return {
          url: endPoints.DASHBOARD,
          method: 'get',
        };
      },
    }),

    showAssigndTask: builder.query({
      query: (params) => {
        return {
          url: endPoints.ASSIGNTASK,
          method: 'get',
          params: { ...params },
        };
      },
      providesTags: ['dashboard'],
    }),

    completeAssigndTask: builder.mutation({
      query: (params) => {
        let { id, taskId } = params;
        return {
          url: endPoints.TASKSCOMPLETE(id, taskId),
          method: 'post',
        };
      },
      invalidatesTags: ['dashboard'],
    }),

    toggleCompleteAssigndTask: builder.mutation({
      query: (params) => {
        let { id, taskId } = params;
        return {
          url: endPoints.TASKSCOMPLETETOGGLE(id, taskId),
          method: 'post',
        };
      },
      invalidatesTags: ['dashboard'],
    }),

    // Manual Order
    CreateAnOrder: builder.mutation({
      query: (data) => {
        return {
          url: endPoints.MANUALORDER,
          method: 'post',
          body: data,
        };
      },
    }),

    AddUserAddress: builder.mutation({
      query: ({ id, ...data }) => {
        return {
          url: endPoints.ADDUSERADDRESS(id),
          method: 'post',
          body: data,
        };
      },
    }),

    GetPatientAddress: builder.mutation({
      query: ({ id }) => {
        return {
          url: endPoints.GETPATIENTADDRESS(id),
          method: 'get',
        };
      },
    }),

    AddPatientAddress: builder.mutation({
      query: ({ ...data }) => {
        return {
          url: endPoints.ADDPATIENTADDRESS(data.patientId),
          method: 'post',
          body: data,
        };
      },
    }),

    UpdatePatientAddress: builder.mutation({
      query: ({ ...data }) => {
        return {
          url: endPoints.UPDATEPATIENTADDRESS(data.patientId, data.id),
          method: 'patch',
          body: data,
        };
      },
    }),

    GetPatientPrescriptionItems: builder.mutation({
      query: ({ id }) => {
        console.log('id', id);
        return {
          url: endPoints.GETPATIENTPRESCRIPTIONITEMS(id),
          method: 'get',
        };
      },
    }),

    UpdateManualOrder: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: endPoints.UPDATEMANUALORDER(id),
          method: 'patch',
          body: data,
        };
      },
    }),

    GetManualOrder: builder.query({
      query: (id) => {
        return {
          url: endPoints.GETMANUALORDER(id),
          method: 'get',
        };
      },
    }),

    GetManualOrderM: builder.mutation({
      query: (id) => {
        return {
          url: endPoints.GETMANUALORDER(id),
          method: 'get',
        };
      },
    }),

    GetAllOrder: builder.query({
      query: () => {
        return {
          url: endPoints.GETALLORDER,
          method: 'get',
        };
      },
    }),

    GeneratePaymentLink: builder.mutation({
      query: ({ id }) => {
        return {
          url: endPoints.GENERATEPAYMENTLINK(id),
          method: 'patch',
        };
      },
    }),

    GetPrescriptionList: builder.query({
      query: ({ id }) => {
        return {
          url: endPoints.PRESCRIPTIONSLIST(id),
          method: 'get',
        };
      },
    }),

    GetOperatorList: builder.query({
      query: () => {
        return {
          url: endPoints.GETOPERATORLIST,
          method: 'get',
        };
      },
    }),

    RecordOrderPayment: builder.mutation({
      query: ({ data }) => {
        return {
          url: endPoints.RECORDORDERPAYMENT(),
          method: 'post',
          body: data,
        };
      },
    }),

    AddGenericNotes: builder.mutation({
      query: ({ data }) => {
        return {
          url: endPoints.GENERICNOTES(),
          method: 'post',
          body: data,
        };
      },
    }),

    GetNotes: builder.query({
      query: (id) => {
        return {
          url: endPoints.GETNOTES(id),
          method: 'get',
        };
      },
    }),
  }),
});

export const {
  useGetDashBoardDataQuery,
  useShowAssigndTaskQuery,
  useCompleteAssigndTaskMutation,
  useToggleCompleteAssigndTaskMutation,
  useCreateAnOrderMutation,
  useUpdateManualOrderMutation,
  useGetManualOrderQuery,
  useGetAllOrderQuery,
  useGeneratePaymentLinkMutation,
  useAddUserAddressMutation,
  useGetPatientAddressMutation,
  useAddPatientAddressMutation,
  useUpdatePatientAddressMutation,
  useGetPatientPrescriptionItemsMutation,
  useGetManualOrderMMutation,
  useGetPrescriptionListQuery,
  useGetOperatorListQuery,
  useRecordOrderPaymentMutation,
  useAddGenericNotesMutation,
  useGetNotesQuery,
} = dashboardSlice;
