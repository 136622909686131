import React, { useEffect, useState } from 'react';
import './rowLinks.css';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';

function RowLinks(props) {
  const { data, activeTab, onClickHandle, getKey, tabData } = props;
  const [activeLink, setActiveLink] = useState(data[0]?.title);

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (activeTab) {
      setActiveLink(data[activeTab].title);
    }
  }, [activeTab]);

  const renderLinks = () => {
    return data.map((item, index) => {
      if (item.hide) {
        return;
      }
      let returnData = !!item.condition;
      if (configData && Array.isArray(configData)) {
        configData.map((confItem) => {
          if (confItem.key == item.condition) {
            returnData = !confItem.isActive;
          }
        });
      } else if (item.condition) {
        return;
      }

      if (returnData) {
        return;
      }

      if (typeof tabData == 'object') {
        let keyData = Object.keys(tabData);

        if (keyData.includes(item.link) && !tabData.show) {
          return;
        }
      } else {
        if (item.link == tabData) {
          return;
        }
      }

      const className = activeLink == item.title ? 'activeLink' : 'inActiveLink';
      return (
        <div
          key={index}
          onClick={() => {
            setActiveLink(item.title);
            if (getKey) {
              onClickHandle(item[getKey]);
            } else {
              onClickHandle(item);
            }
          }}
        >
          <div className={className} style={{ ...item.titleStyle }}>
            {item.title}
          </div>
        </div>
      );
    });
  };

  return <div className="d-flex linksFlexDiv">{renderLinks()}</div>;
}

export default RowLinks;
