import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'rsuite';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { ArrowRight, profielLogout, profielSettings } from 'res/images';
import { Loader } from 'whealth-core-web/components';
import { profileImage, teamRoles, titlizeString } from 'whealth-core-web/components/Helper.js';
import useApiManager from 'networking/ApiManager';

function AdminPanelHeader(props) {
  const { iconTitle, title, showTree, searchBox, showIcon } = props;
  const navigate = useNavigate();

  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const ApiClient = useApiManager();
  let { isLoading } = useGetLoginUserDataQuery();

  //  <span class="material-icons-outlined">grid_view</span>
  //           <span class="material-icons-outlined">person_outline</span>
  //           <span class="material-icons-outlined">description</span>
  //           <span class="material-icons-outlined">people_alt</span>
  //           <span class="material-icons-outlined">settings</span>

  const breadCrumbLink = (item) => {
    item.link && navigate(item.link);
  };

  const handleLogout = () => {
    ApiClient.currentAdminProfile()
      .then((res) => {
        window.webengage.user.logout(res.data.uuid);
        localStorage.clear();
        window.location.href = '/';
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderHeaderTitle = () => {
    if (showTree) {
      return (
        <div className='d-flex breadcrumbprofile'>
          {showTree.map((item, index) => {
            return (
              <div className='d-flex' key={index}>
                {index > 0 && index + 1 <= showTree.length && (
                  <div className='arrow'>
                    <span class='material-icons'>chevron_right</span>
                  </div>
                )}
                <div key={index} className=''>
                  <div onClick={() => breadCrumbLink(item)}>
                    {item?.iconClass ? (
                      <span className='material-icons-outlined breadcrumbpic'>{item?.iconClass}</span>
                    ) : item?.icon ? (
                      <img className='breadcrumbpic' src={item?.icon} />
                    ) : (
                      ''
                    )}
                    <span className='capitalize'>{item?.title}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className='d-flex align-items-center breadcrumbIcon'>
          <span className='material-icons-outlined'>{iconTitle}</span>
          <div>{title}</div>
        </div>
      );
    }
  };

  const currentAdminProfile = () => {
    navigate(`/team/${userData.id}/edit`, {
      state: { userData },
    });
  };

  return (
    <div className='d-flex  align-items-center  justify-content-between header-main mb-4'>
      <div>{renderHeaderTitle()}</div>
      <div className='d-flex align-items-center w-40 header-right-part gap-16'>
        {searchBox}

        <div className='userDp profile-dropdown-menu'>
          <Dropdown
            noCaret
            placement='bottomEnd'
            title={
              <img
                src={profileImage(userData.gender, userData.profile_url)}
                className='nav-link dropdown-toggle userImage'
                data-bs-toggle='dropdown'
                href='#'
                role='button'
                aria-expanded='false'
              />
            }
          >
            <Dropdown.Item>
              <div className='d-flex  dropdown-profile '>
                <div className='dropdown-profile-pic '>
                  <img
                    src={profileImage(userData.gender, userData.profile_url)}
                    className='nav-link dropdown-toggle userImage'
                    data-bs-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-expanded='false'
                  />
                </div>
                <div className='dropdown-profile-name capitalize'>
                  {userData.full_name}
                  <span className='d-flex'>{teamRoles(userData.role)}</span>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div onClick={currentAdminProfile} className='profilesub_menu'>
                <span class='material-icons-outlined'>settings</span>
                My Profile
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <div
                className='profilesub_menu'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let windoConfirm = window.confirm('Are you sure you want to sign out?');
                  if (windoConfirm) {
                    handleLogout();
                  }
                }}
              >
                <span class='material-icons-outlined'>logout</span>
                Sign Out
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default AdminPanelHeader;
