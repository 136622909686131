import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { closeIcon } from 'res/images';
import {
  CoreButton,
  CoreCheckBox,
  CoreDropDown,
  CoreInputBox,
  CoreTextArea,
  ReusableButton,
  Select2DropDown,
} from 'whealth-core-web/components';
import { searchIcon } from 'res/images';
import { useLocation, useParams } from 'react-router-dom';
import { checkunique, formulationData } from 'whealth-core-web/components/Helper';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import CoreRadioBox from 'whealth-core-web/components/CoreRadioBox';
import { Button, Modal } from 'rsuite';
import { useSelector } from 'react-redux';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function AddPrescriptionForm(props) {
  const { onClick, setErrors, errors, data, setAlertType, setIsShowAlert } = props;
  const location = useLocation();
  const duplicateId = location?.state?.id;
  const userData = useGetLoginUserDataQuery()?.data?.data || {};

  const [mandatoryMedicine, setMandatoryMedicine] = useState(false);
  const [doctorList, setDoctorList] = useState([]);

  const getCockpitData = useGetCockpitDataQuery();

  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    configData?.map((item) => {
      if (item.key == 'mandatory_medicine' && item.isActive) {
        setMandatoryMedicine(true);
      }
    });
  }, configData);

  const initialValues = {
    dosage: '000',
    medicine_id: '',
    duration: 0,
    frequency: '',
    note: '',
    meal_duration_unit: 'hour',
    meal_duration_value: null,
    is_optional: false,
    medicine: { name: undefined },
  };
  let { id, pid } = useParams();

  const [prescriptionData, setPrescriptionData] = useState({
    prescription_medicines: [],
  });

  const [medicineList, setMedicineList] = useState([]);
  const [medicineListId, setMedicineListId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isCreateModal, setIsCreateModal] = useState();
  const [newMedicineName, setNewMedicineName] = useState('');
  const [medicineFormulation, setMedicineFormulation] = useState();
  const [medicineError, setMedicineError] = useState({});
  const [createMedicineIndex, setCreateMedicineIndex] = useState();

  const ApiClient = useApiManager();

  useEffect(() => {
    ApiClient.prescriptionDoctorList(id, 'doctor').then((res) => {
      setDoctorList(res.data);
    });
  }, []);

  useEffect(() => {
    if (!pid && !duplicateId) {
      setPrescriptionData({
        advice: '',
        diagnosis: '',
        prescription_medicines: mandatoryMedicine ? [{ ...initialValues }] : [],
      });
    }
  }, [pid, duplicateId, mandatoryMedicine]);

  useEffect(() => {
    handleOnFocus();
  }, []);

  useEffect(() => {
    setMedicineListId(medicineListId);
  }, [medicineListId]);

  useEffect(() => {
    if (data) {
      let tempData = { ...data };
      tempData.goal_id = data.goal.id;
      tempData.title = data.goal.title;
      if (tempData.prescription_medicines) {
        tempData.prescription_medicines.forEach((item, index) => {
          item.medicine_id = item.medicine?.id;
          delete tempData.department;

          if (duplicateId) {
            delete item.id;
          }
        });
      }
      if (duplicateId) {
        delete tempData.status;
      }
      setPrescriptionData(tempData);
    }
  }, [data]);

  const removeForm = (index) => {
    let dataObj = prescriptionData;
    dataObj.prescription_medicines?.splice(index, 1);
    errors?.prescription_medicines?.splice(index, 1);

    setErrors({ ...errors });
    setPrescriptionData((prevState) => {
      return { ...prevState, ...dataObj };
    });
  };

  const typeArray = [
    { title: 'Daily', id: 'daily' },
    { title: 'Weekly', id: 'weekly' },
    { title: 'Monthly', id: 'monthly' },
    { title: 'Yearly', id: 'yearly' },
  ];

  const typeArray2 = [
    { title: '0.25', id: '0.25' },
    { title: '0.50', id: '0.5' },
    { title: '1', id: '1' },
    { title: '2', id: '2' },
  ];

  const filterFrequencyOptions = (duration) => {
    if (duration < 7) {
      return typeArray.filter((item) => item.id === 'daily');
    } else if (duration < 28) {
      return typeArray.filter((item) => item.id === 'daily' || item.id === 'weekly');
    } else if (duration < 365) {
      return typeArray.filter((item) => item.id === 'daily' || item.id === 'weekly' || item.id === 'monthly');
    } else {
      return typeArray.filter(
        (item) => item.id === 'daily' || item.id === 'weekly' || item.id === 'monthly' || item.id === 'yearly'
      );
    }
  };

  const typeArr3 = [
    { title: 'Before Meal', id: 'before_meal' },
    { title: 'With Meal', id: 'with_meal' },
    { title: 'After Meal', id: 'after_meal' },
  ];

  const handleChangeFields = (data, key) => {
    prescriptionData[key] = data;
    setPrescriptionData({ ...prescriptionData });
  };

  // const handleArrChange = (data, key, index, item) => {
  //   prescriptionData.prescription_medicines[index][key] = data || '';
  //   setPrescriptionData({ ...prescriptionData });
  // };
  const handleArrChange = (data, key, index, item) => {
    if (key === 'medicine_id') {
      const selectedMedicine = medicineList.find((medicine) => medicine.id === data);
      prescriptionData.prescription_medicines[index][key] = data || '';
      prescriptionData.prescription_medicines[index].dosage = selectedMedicine?.dosage || '000';
      prescriptionData.prescription_medicines[index].duration = selectedMedicine?.duration || 0;
      prescriptionData.prescription_medicines[index].frequency = selectedMedicine?.frequency || '';
      prescriptionData.prescription_medicines[index].meal_time = selectedMedicine?.meal_time || '';
      prescriptionData.prescription_medicines[index].is_optional = selectedMedicine?.is_optional || false;
      prescriptionData.prescription_medicines[index].meal_duration_unit =
        selectedMedicine?.meal_duration_unit || 'hour';
      prescriptionData.prescription_medicines[index].meal_duration_value = selectedMedicine?.meal_duration_value || '';
      prescriptionData.prescription_medicines[index].dose_count = selectedMedicine?.dose_count || '1';
      prescriptionData.prescription_medicines[index].note = selectedMedicine?.note || '';
      setPrescriptionData({ ...prescriptionData });
    } else {
      prescriptionData.prescription_medicines[index][key] = data || '';
      setPrescriptionData({ ...prescriptionData });
    }
  };

  const handleArrChangeNewSearch = (data, key, index) => {
    prescriptionData.prescription_medicines[index][key] = data || '';
    setPrescriptionData({ ...prescriptionData });
  };

  const handleCheckBoxClick = (val, isChecked, index, arrvalues) => {
    let values = arrvalues;
    if (val && isChecked) {
      values[val] = '1';
    } else if (val && !isChecked) {
      values[val] = 0;
    }
    // if (values.includes('1')) {
    //   if (!prescriptionData.prescription_medicines[index].meal_time) {
    //     prescriptionData.prescription_medicines[index].meal_time = 'before_meal';
    //   }
    // } else {
    //   delete prescriptionData.prescription_medicines[index].meal_time;
    //   prescriptionData.prescription_medicines[index].meal_duration_value = null;
    //   prescriptionData.prescription_medicines[index].meal_duration_unit = '';
    // }

    let arrToString = values.join('');
    prescriptionData.prescription_medicines[index].dosage = arrToString;
    setPrescriptionData({ ...prescriptionData });
  };

  const handleMealTime = (val, index) => {
    prescriptionData.prescription_medicines[index].meal_time = val;
    if (val == 'with_meal') {
      prescriptionData.prescription_medicines[index].meal_duration_unit = null;
      prescriptionData.prescription_medicines[index].meal_duration_value = null;
    }
    setPrescriptionData({ ...prescriptionData });
  };

  const handleOnFocus = () => {
    let params = {
      page: 1,
    };
    ApiClient.getMedicines(params)
      .then((res) => {
        setMedicineList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const createMedicines = (index) => {
    setIsCreateModal(true);
    let newMedicine = prescriptionData.searchData[index][0];
    let medicineName = newMedicine?.name?.slice(19, newMedicine.name.length);
    setNewMedicineName(medicineName);
    prescriptionData.searchData = [];
    setPrescriptionData({ ...prescriptionData });
    setCreateMedicineIndex(index);
  };

  const handleCancelClick = () => {
    setIsCreateModal(false);
    setNewMedicineName('');
    setMedicineFormulation('');
    setMedicineError({});
  };
  const handleSave = () => {
    let params = { name: newMedicineName };
    if (medicineFormulation && medicineFormulation != '') {
      params.formulation = medicineFormulation;
    }
    ApiClient.createMedicinesDraft(params)
      .then((res) => {
        setMedicineList([...medicineList, res.data]);
        setAlertType('alert-success');
        setIsShowAlert('Medicine Created Successfully');
        handleCancelClick();
        handleArrChange(res.data?.id, 'medicine_id', createMedicineIndex);
      })
      .catch((err) => {
        if (err?.response?.data?.errors || err?.response?.data?.detail) {
          setMedicineError(err.response.data.errors);
          setAlertType('alert-danger');
          setIsShowAlert(err?.response?.data?.detail);
        }
      });
  };

  const createMedicineModal = () => {
    return (
      <Modal backdrop='static' keyboard={false} open={isCreateModal} onClose={handleCancelClick}>
        <Modal.Header>
          <Modal.Title>Create Medicine</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex flex-column'>
            <CoreInputBox
              showMSG={medicineError?.name}
              placeholder='Medicine Name'
              value={newMedicineName || ''}
              label='Medicine Name'
              setValue={(inputVal) => {
                setNewMedicineName(inputVal);
                // setValueObj({ ...valueObj, name: inputVal });
              }}
              maxLength={255}
              msgStyle={{ color: 'red' }}
              retuired
            />

            <div className=' w-100 mt-4'>
              <Select2DropDown
                value={medicineFormulation || ''}
                data={formulationData()}
                labelKey='title'
                label={'Medicine Formulation'}
                placeholder={'Medicine Formulation'}
                valueKey='id'
                retuired
                onSelect={(inputVal) => {
                  setMedicineFormulation(inputVal);
                }}
                showMSG={medicineError?.formulation}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSave()} appearance='primary'>
            Create
          </Button>
          <Button onClick={() => handleCancelClick()} appearance='default'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const searchMedicines = (query, id) => {
    if (!prescriptionData.searchData) {
      prescriptionData.searchData = [];
    }
    setSearchValue(query);
    if (query?.trim()?.length > 2) {
      ApiClient.searchMedicines(query)
        .then((res) => {
          prescriptionData.searchData[id] = res.data.length
            ? res.data
            : [
                {
                  name: `Add New Medicine : ${query}`,
                  medicine_title_repr: `Add New Medicine : ${query}`,
                  iscreate: true,
                },
              ];
          setPrescriptionData({ ...prescriptionData });
        })
        .catch((err) => console.log(err));
    } else if (
      prescriptionData.searchData[id]?.length &&
      prescriptionData.searchData[id][0]?.name?.includes('Add New Medicine : ')
    ) {
      prescriptionData.searchData = [];
      setPrescriptionData({ ...prescriptionData });
    }
  };

  const handleAsNeededClick = (isChecked, index) => {
    prescriptionData.prescription_medicines[index].is_optional = isChecked;
    if (isChecked) {
      prescriptionData.prescription_medicines[index].duration = 0;
      prescriptionData.prescription_medicines[index].dosage = '000';
      prescriptionData.prescription_medicines[index].duration = 0;
      prescriptionData.prescription_medicines[index].meal_time = '';
      prescriptionData.prescription_medicines[index].meal_duration_unit = null;
      prescriptionData.prescription_medicines[index].frequency = null;
      prescriptionData.prescription_medicines[index].meal_duration_value = null;
    } else {
      prescriptionData.prescription_medicines[index].duration = 0;
      prescriptionData.prescription_medicines[index].dosage = '000';
    }
    setPrescriptionData({ ...prescriptionData });
  };

  const mealTimeDuration = [
    { title: 'Hour', id: 'hour' },
    { title: 'Minute', id: 'minute' },
  ];

  const renderNestingFields = (res) => {
    return prescriptionData?.prescription_medicines?.map((item, index) => {
      let defaultData;
      let allData = medicineList;
      if (item?.medicine?.id) {
        defaultData = item?.medicine;
        allData = [...allData, defaultData];
      }
      if (prescriptionData?.searchData && prescriptionData?.searchData[index]) {
        allData = checkunique(medicineList, prescriptionData?.searchData[index]);
      }

      let arrvalues = item?.dosage?.split('') || [0, 0, 0];
      const numbers = arrvalues.map(Number);
      const sum = numbers.reduce((total, num) => total + num, 0);
      let marginBotton = prescriptionData.prescription_medicines.length - 1 != index && 'mb-4';

      // const renderCloseIcon = () => {
      //   if (index > 0 && prescriptionData.prescription_medicines.length - 1 == index) {
      //     return (
      //       <div className='justify-content-end ' key={index}>
      //         <div className='pointer' onClick={() => removeForm(index)}>
      //           <img src={closeIcon} />
      //         </div>
      //       </div>
      //     );
      //   }
      // };

      return (
        <div className={`card mt-3 ${marginBotton}`} key={index}>
          <div className='card-body'>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='card-title pt-2 pb-2'>Add Medicine</h5>
              {/* {renderCloseIcon()} */}
            </div>
            {createMedicineModal()}
            <div className='d-md-flex prescription-box'>
              <div className='w-100 mb-3'>
                <>
                  <div className='searchBarInput'>
                    <Select2DropDown
                      isValidation
                      getObjData
                      onSearch={(query) => searchMedicines(query, index)}
                      label='Medicine Name'
                      data={allData}
                      style={{ width: '100%' }}
                      labelKey='medicine_title_repr'
                      valueKey='id'
                      placeholder='Search Medicine Name'
                      value={prescriptionData.prescription_medicines[index].medicine_id}
                      msgStyle={{ color: 'red' }}
                      retuired
                      onSelect={(data, dataObj) => {
                        handleArrChange(data, 'medicine_id', index);
                        dataObj?.iscreate && createMedicines(index);
                      }}
                    />

                    <div className='searchbarIcon mt-3'>
                      <img src={searchIcon} />
                    </div>
                  </div>
                  <div className='inputMsg'>
                    {errors?.prescription_medicines &&
                      errors?.prescription_medicines[index] &&
                      errors?.prescription_medicines[index].medicine_id}
                  </div>
                </>
              </div>
              {!item?.is_optional && (
                <div className='w-100  d-flex'>
                  <div className='w-100 mb-3'>
                    <CoreInputBox
                      maxLength={3}
                      retuired
                      validateZero
                      inputStyle={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      setErrors={setErrors}
                      labelStyle={{ fontWeight: 'var(--lightWeight)' }}
                      label='Course Duration'
                      placeholder='Course Duration '
                      value={item.duration || ''}
                      showMSG={
                        errors?.prescription_medicines &&
                        errors?.prescription_medicines[index] &&
                        errors?.prescription_medicines[index].duration
                      }
                      setValue={(data) => {
                        handleArrChange(data, 'duration', index);
                      }}
                      validateNumbers
                    />
                    {errors.length && <div className='inputMsg'>{errors}</div>}
                  </div>

                  <div className='mt-4'>
                    <div className='unitBox'>Days</div>
                  </div>
                </div>
              )}

              {!item?.is_optional && (
                <div className='w-100 mb-3'>
                  <CoreDropDown
                    retuired
                    showMSG={
                      errors?.prescription_medicines &&
                      errors?.prescription_medicines[index] &&
                      errors?.prescription_medicines[index].frequency
                    }
                    data={filterFrequencyOptions(item.duration)}
                    labelStyle={{
                      fontWeight: 'var(--lightWeight)',
                    }}
                    label='Frequency'
                    placeholder='How many times '
                    value={item.frequency}
                    setValue={(data) => {
                      handleArrChange(data, 'frequency', index);
                    }}
                  />
                </div>
              )}
              <div className='w-100 mb-3'>
                {console.log('item.dose_count', item.dose_count)}
                <CoreDropDown
                  // retuired
                  showMSG={
                    errors?.prescription_medicines &&
                    errors?.prescription_medicines[index] &&
                    errors?.prescription_medicines[index].dose_count
                  }
                  data={typeArray2}
                  labelStyle={{
                    fontWeight: 'var(--lightWeight)',
                  }}
                  label='Dose Quantity'
                  placeholder='How many times '
                  value={item.dose_count || '1'}
                  setValue={(data) => {
                    handleArrChange(data, 'dose_count', index);
                  }}
                />
              </div>
            </div>

            <div className='d-md-flex prescription-box'>
              <div className='w-100 mb-3'>
                <div className='d-flex'>
                  <span className='requiredInput me-1'> * </span>
                  <div style={{ color: 'var(--lightBlack)' }}>Dose</div>
                </div>
                <div className='mt-2 ms-4'>
                  <CoreCheckBox
                    disabled={item?.is_optional}
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    value={0}
                    className={'me-4'}
                    title='Morning'
                    id={`flexCheckDefault${index}`}
                    checked={!!parseInt(item.dosage[0])}
                    htmlFor={`flexCheckDefault${index}`}
                  />
                  <CoreCheckBox
                    disabled={item?.is_optional}
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    value={1}
                    className={'me-4'}
                    title='Afternoon'
                    checked={!!parseInt(item.dosage[1])}
                    id={`flexCheckDefault1${index}`}
                    htmlFor={`flexCheckDefault1${index}`}
                  />

                  <CoreCheckBox
                    disabled={item?.is_optional}
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    value={2}
                    title='Night'
                    className={'me-4'}
                    checked={!!parseInt(item.dosage[2])}
                    id={`flexCheckDefault2${index}`}
                    htmlFor={`flexCheckDefault2${index}`}
                  />
                  <CoreCheckBox
                    onChange={(val, checked) => handleAsNeededClick(checked, index)}
                    disabled={item.dosage[0] == 1 || item.dosage[1] == 1 || item.dosage[2] == 1}
                    title='As Needed'
                    className={'me-4'}
                    checked={!!item.is_optional}
                    id={`flexCheckDefault3${index}`}
                    htmlFor={`flexCheckDefault3${index}`}
                  />
                </div>
                <div className='inputMsg'>
                  {errors?.prescription_medicines &&
                    errors?.prescription_medicines[index] &&
                    errors?.prescription_medicines[index].dosage}
                </div>
              </div>
              {!item?.is_optional && (
                <div className='w-100 mb-3'>
                  <div className='w-100'>
                    <div>
                      <Select2DropDown
                        value={item?.meal_time || ''}
                        data={typeArr3}
                        showMSG={
                          errors?.prescription_medicines &&
                          errors?.prescription_medicines[index] &&
                          errors?.prescription_medicines[index].meal_time
                        }
                        labelKey='title'
                        label={'Medicine Intake'}
                        placeholder={'Medicine Intake'}
                        valueKey='id'
                        onSelect={(data) => {
                          handleArrChange(data, 'meal_time', index);
                        }}
                      />
                    </div>

                    {item.meal_time !== 'with_meal' && (
                      <>
                        <label className='inputlabel mt-3 '>{'Wait Time'}</label>
                        <div className='d-flex spacifictime-box w-100'>
                          <CoreInputBox
                            maxLength={2}
                            disabled={item.meal_time !== 'before_meal' && item.meal_time !== 'after_meal'}
                            validateNumbers
                            retuired
                            value={item.meal_duration_value || ''}
                            setValue={(data) => {
                              handleArrChange(data, 'meal_duration_value', index, item);
                            }}
                            data={mealTimeDuration}
                            placeholder='Time'
                          />
                          <div className='specify-time-unit'>
                            <CoreDropDown
                              disabled={!item.meal_duration_value}
                              retuired={mandatoryMedicine}
                              value={item.meal_duration_unit || 'hour'}
                              setValue={(data) => {
                                handleArrChange(data, 'meal_duration_unit', index);
                              }}
                              data={mealTimeDuration}
                              placeholder='Unit'
                              selectStyle={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            />
                          </div>
                        </div>
                        <div className='inputMsg ms-3'>
                          {errors?.prescription_medicines &&
                            errors?.prescription_medicines[index] &&
                            (errors?.prescription_medicines[index].meal_duration_value ||
                              errors?.prescription_medicines[index].meal_duration_unit)}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className='w-100 mb-3'>
                <CoreTextArea
                  prescriptionLabel
                  rows={5}
                  showMSG={
                    errors?.prescription_medicines &&
                    errors?.prescription_medicines[index] &&
                    errors?.prescription_medicines[index].note
                  }
                  label='Note'
                  placeholder='Instruction'
                  value={item.note}
                  setValue={(data) => {
                    handleArrChange(data, 'note', index);
                  }}
                />
              </div>
            </div>
            {(!mandatoryMedicine || prescriptionData.prescription_medicines.length > 1) && (
              <div className='p-3'>
                <ReusableButton onClick={() => removeForm(index)} outline title='Remove' />
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const renderFormDetails = () => {
    return (
      <>
        <div className='card mt-3'>
          <div className='card-body'>
            <h5 className='card-title pt-2 pb-2'>Observation Details</h5>
            {userData.role !== 'doctor' && (
              <div>
                <Select2DropDown
                  retuired
                  label={'Select Doctor'}
                  showMSG={errors?.doctor_id}
                  valueKey='id'
                  labelKey='full_name'
                  value={prescriptionData.doctor_id ? prescriptionData.doctor_id : prescriptionData.doctor?.id || ''}
                  onSelect={(data) => {
                    handleChangeFields(data, 'doctor_id');
                  }}
                  onClean={() => {
                    prescriptionData.doctor.id = '';
                  }}
                  data={doctorList}
                  placeholder='Select Doctor'
                />
              </div>
            )}
            <div className='mt-3'>
              <CoreTextArea
                showMSG={errors?.patient_history}
                labelStyle={{
                  fontWeight: 'var(--lightWeight)',
                }}
                rows={2}
                label='Patient History'
                placeholder='Patient History '
                value={prescriptionData.patient_history}
                setValue={(data) => {
                  handleChangeFields(data, 'patient_history');
                }}
              />
            </div>
            <div className='mt-3'>
              <CoreTextArea
                showMSG={errors?.diagnosis}
                placeholder='Diagnosis'
                value={prescriptionData.diagnosis}
                label='Diagnosis'
                setValue={(data) => {
                  handleChangeFields(data, 'diagnosis');
                }}
                msgStyle={{ color: 'red' }}
                retuired
                rows={2}
              />
            </div>
          </div>
        </div>

        {renderNestingFields()}
        <CoreButton
          onClick={() => {
            prescriptionData.prescription_medicines.push({
              ...initialValues,
            });
            errors?.prescription_medicines && errors?.prescription_medicines.push({});
            setPrescriptionData({ ...prescriptionData });
          }}
          title='+ Add Medicine'
        />
        <div className='card mt-3'>
          <div className='card-body'>
            <h5 className='card-title pt-2 pb-2'>More Details</h5>
            <CoreTextArea
              showMSG={errors?.advice}
              labelStyle={{
                margin: '8px 0 8px 6px',
                fontWeight: 'var(--lightWeight)',
              }}
              rows={2}
              label='Advice'
              retuired
              placeholder='Advice'
              value={prescriptionData.advice}
              setValue={(data) => {
                handleChangeFields(data, 'advice');
              }}
            />
          </div>
        </div>

        <CoreTextArea
          labelStyle={{
            margin: '8px 5px 8px 6px',
            fontWeight: 'var(--lightWeight)',
          }}
          rows={2}
          label='Notes For  team'
          placeholder='Add Note Here'
          value={prescriptionData.note}
          setValue={(data) => {
            handleChangeFields(data, 'note');
          }}
        />
        <CoreButton
          onClick={() => {
            setErrors({});
            onClick(prescriptionData);
          }}
          customClass='btn btn-secondary btn-lg'
          title='Save Details'
        />
      </>
    );
  };
  return <div className='prescription-form'>{renderFormDetails()}</div>;
}
export default AddPrescriptionForm;
