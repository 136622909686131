import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editIconBlack, linkUrl, upload, uploaded } from 'res/images';
import { dateFormat, profileImage, timeFormat, titlizeString } from './Helper';
import Loader from './Loader';
import { Pagination } from 'whealth-core-web/components';
import CommonToolTip from './CommonToolTip';
import { useDispatch } from 'react-redux';
import { setReduxReportData } from 'redux/Slices';
import { ToastContainer, toast } from 'react-toastify';

function RenderReportTab(props) {
  const { uploadReport } = props;
  const ApiClient = useApiManager();
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deepLink, setDeepLink] = useState('');

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    getPatientReport();
  }, [id, currentPage]);

  const handleClick = (id, reportid) => {
    getReportsDeeplink(id, reportid);
    toast.success('Link copied successfully', { autoClose: 2000 });
  };

  const getReportsDeeplink = (id, reportId) => {
    ApiClient.getReportDeeplink(id, reportId)
      .then((res) => {
        navigator.clipboard.writeText(res.data.deep_link);
        setDeepLink(res.data);
      })
      .catch((err) => {
        if (err.response.data.detail == 'Not found.') {
          setDeepLink('');
        }
      });
  };

  const getPatientReport = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getPatientReports(id, false, params)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setReportData(res.data);
        dispatch(setReduxReportData(res.data));
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderReportDetails = (item) => {
    return (
      <div className='cardDataReportBorder'>
        <div className='cardMilestone w-100'>
          <div className='d-flex'>
            <div className='newMilestone info w-100 reportTab'>
              <div className='p-2 mt-3 mileStone rounded'>
                <div className='d-flex justify-content-between'>
                  <div className='cardHeading'>
                    <div className='ms-auto semiBold'>
                      <div className='capitalize'>
                        <div className='d-flex justify-content-between align-items-center'>
                          {item?.title && <strong className=' pointer'>{item.title}</strong>}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex '>
                      {item?.status && <div class='mt-2  badge badge-light capitalize'>{item?.status}</div>}
                      {item.channel && <div class='ms-2 mt-2 badge badge-light capitalize'>{item.channel}</div>}
                    </div>
                    <div className='d-flex mt-2'>
                      <div className='d-flex  w-100'>
                        <span className='prescriptiontime capitalize'>{item.description}</span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='d-flex'>
                      <div className='ms-auto d-flex'>
                        <div className='mx-2'>
                          {item.report && (
                            <CommonToolTip
                              placement={'top'}
                              item={
                                <a href={item.report} target='_blank'>
                                  <img src={uploaded} className='icon' />
                                </a>
                              }
                              src={editIconBlack}
                              className='icon'
                              style={{ cursor: 'pointer' }}
                              showValue={'Download Report'}
                            />
                          )}
                        </div>
                      </div>
                      <div className='d-flex '>
                        <div className='d-flex ms-auto'>
                          <div className='deeplinks' style={{ color: '#eef1f3', width: '25px' }}>
                            <CommonToolTip
                              placement={'top'}
                              item={
                                <img
                                  onClick={() => handleClick(item.user.id, item.id)}
                                  src={linkUrl}
                                  className='icon'
                                  style={{ cursor: 'pointer' }}
                                />
                              }
                              showValue={'Deeplink'}
                            />
                          </div>
                          <div className='goalAchivement'>
                            <CommonToolTip
                              placement={'top'}
                              item={
                                <img
                                  onClick={() =>
                                    navigate(`/patient/${id}/reports/${item.id}/edit`, {
                                      state: {
                                        item,
                                      },
                                    })
                                  }
                                  src={editIconBlack}
                                  className='icon'
                                  style={{ cursor: 'pointer' }}
                                />
                              }
                              showValue={'Edit'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-2 justify-content-end date  font-14px text-end'>
                      {item?.admin?.full_name}
                      <div className='date text-nowrap'>
                        {item && item.updated_at && dateFormat(item.updated_at) + ' · ' + timeFormat(item.updated_at)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='d-flex'>
        <div className='pt-3'>
          <button onClick={uploadReport} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={upload} className='icon' />
            Upload Report
          </button>
        </div>
      </div>
      {reportData &&
        reportData.map((item, index) => {
          return <div key={index}>{renderReportDetails(item)}</div>;
        })}
      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default RenderReportTab;
