import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import CommonAlert from './CommonAlert';
import { dateFormat, profileImage, timeFormat } from './Helper';
import Pagination from './Pagination';

function Auditlog(props) {
  const { setIsLoading, isLoading } = props;

  const ApiClient = useApiManager();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [alertError, setAlertError] = useState('');
  const [alertType, setAlertType] = useState('alert-success');

  useEffect(() => {
    getAudit();
  }, [currentPage]);

  const getAudit = () => {
    setIsLoading(true);
    let pageNo = { page: currentPage };
    ApiClient.getAUDITLOG(pageNo)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setData(res.data);
      })
      .catch((err) => {
        console.warn(err);
        setIsLoading(false);
        setAlertType('alert-danger');
        setAlertError(err.response.data.detail);
      });
  };

  let renderKeys = (item, keys) => {
    return keys.map((keyStr, index) => {
      return (
        <tr key={index}>
          <td>
            <strong>{keyStr}</strong>
          </td>
          <td className='audit-log-red'>{item.changes[keyStr][0]}</td>
          <td className='audit-log-green'>{item.changes[keyStr][1]}</td>
        </tr>
      );
    });
  };

  const renderAudit = (item) => {
    return (
      <div className='audit-log-screen w-100'>
        <div className='d-flex justify-content-between audit-log-top-bar mb-3'>
          <div className='audit-log-left d-flex align-items-center'>
            <div className='badge capitalize mb-0'>{item.content_type_name}</div>
            <div>
              {dateFormat(item.timestamp)} · {timeFormat(item.timestamp)}
            </div>
          </div>
          <div className='audit-log-right d-flex align-items-center'>
            <div className='audit-log'>
              <sapn>By </sapn>
              <img
                className='rounded coachImage  img-fluid'
                src={profileImage(item?.changed_by?.gender, item?.changed_by?.profile_url)}
              />
              <a href='#'>{item?.changed_by?.full_name || 'System'}</a>
            </div>
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table table-bordered mb-0'>
            <tbody>{renderKeys(item, Object.keys(item.changes))}</tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className='w-100'>
      <CommonAlert isShowAlert={alertError} alertType={alertType} setIsShowAlert={setAlertError} className='mt-1' />
      {data.map((item, index) => {
        return <div key={index}>{renderAudit(item)}</div>;
      })}
      <div className='d-flex justify-content-end my-3 auditPagination'>
        <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
}

export default Auditlog;
