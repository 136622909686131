import React, { useEffect, useState } from 'react';
import { closeIcon } from 'res/images';
import {
  DateRange,
  CategoryFilter,
  TagsFilter,
  AuthorFilter,
  LanguageView,
  GenderFilterView,
  BMIFilter,
  OrderStatusFilter,
  OrderTypeFilter,
  PriceRangeFilter
} from './index';
import './filterModal.scss';
import { clickOnElmOnBodyClick } from './Helper';
import labels from '../../inputFields.json';

function FilterModal(props) {
  const { getData, data, filter, clearFiltersData, setClearFilterData, hideBmiFilter } = props;
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const getFilterData = (data) => {
    setFilterData({ ...filterData, ...data });
  };

  const handleClearDate = () => {
    let obj = { ...filterData };
    if (obj['date']) {
      delete obj['date'];
    }

    setFilterData(prevState => {
      const updatedData = { ...prevState };
      if ('date' in updatedData) {
        delete updatedData['date'];
      }
      return updatedData;
    });
  };
  const filterHeader = () => {
    return (
      <div className='filterHeader'>
        <div>Filters</div>
        <div className='' data-bs-dismiss='offcanvas' aria-label='Close'>
          <img src={closeIcon} />
        </div>
      </div>
    );
  };

  const genderFilter = () => {
    return (
      <div className='ml-8'>
        <GenderFilterView filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const renderBMIFilter = () => {
    return (
      <div className='ml-8'>
        <BMIFilter filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const renderOrderStatusFilter = () => {
    return (
      <>
        <div className='ml-8'>
          <OrderStatusFilter filterData={data} setFilterData={getFilterData} />
        </div>
        <div className='hrLine' />
      </>
    );
  };

  const renderOrderTypeFilter = () => {
    return (
      <>
        <div className='ml-8'>
          <OrderTypeFilter filterData={data} setFilterData={getFilterData} clearFiltersData={clearFiltersData} setClearFilterData={setClearFilterData} />
        </div>
        <div className='hrLine' />
      </>
    );
  };

  const renderPriceRangeFilter = () => {
    return (
      <div className='ml-8'>
        <PriceRangeFilter filterData={data} setFilterData={getFilterData} clearFiltersData={clearFiltersData} setClearFilterData={setClearFilterData} />
      </div>
    );
  };

  // const isDateInRange = (date, ranges) => {
  //   return ranges.some(range => {
  //     const [start, end] = range.value;
  //     return date >= start && date <= end;
  //   });
  // };
  

  const showDateRange = () => {
    return (
      <div>
        <DateRange
          disableDate={(date) => date >= new Date()}
          //disableDate={isDateInRange}
          clearFiltersData={clearFiltersData}
          filterData={data}
          setFilterData={getFilterData}
          handleClearDate={handleClearDate}
          title='By Date'
          useFormattedDate = {filter == 'orders' ? true : false}
        />
        <div className='hrLine' />
      </div>
    );
  };

  const categoryFilter = () => {
    return (
      <div className='categoryFileter'>
        <CategoryFilter clearData={clearFiltersData} filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const showAutherFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter
          title='Select Author'
          placeholder='Search Author'
          api='article'
          filterData={data}
          setFilterData={getFilterData}
        />
      </div>
    );
  };

  const teamPatientsFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter
          title='Assigned Patients'
          placeholder='Search Assigned Patients'
          api='team'
          filterData={data}
          setFilterData={getFilterData}
        />
      </div>
    );
  };

  const patientAdminFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter
          title={labels?.patientFilterSearch?.label || 'Doctor, Health Coach'}
          placeholder={labels?.patientFilterSearch?.placeholder || 'Search Doctor, Health Coach'}
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
        />
      </div>
    );
  };

  const languagesFilter = () => {
    return (
      <div className='ml-8'>
        <LanguageView clearFiltersData={clearFiltersData} filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const tagsFilter = () => (
    <div className='categoryFileter'>
      <TagsFilter filterData={data} setFilterData={getFilterData} />
    </div>
  );

  const filterButtons = () => {
    return (
      <div className='footerButtons'>
        <div
          className='button clearBtn'
          onClick={() => {
            setFilterData('');
            getData({});
            setClearFilterData(!clearFiltersData);
            clickOnElmOnBodyClick('datePickerContainer', 'rs-picker-toggle-clean', 'class');
          }}
        >
          Clear All
        </div>
        <div
          onClick={() => {
            getData(filterData);
          }}
          className='button applyBtn'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          Apply Filter
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='renderFooter'>
        <div>{filterButtons()}</div>
      </div>
    );
  };

  const renderFilters = () => {
    if (filter == 'team') {
      return (
        <>
          {showDateRange()}
          <div className='my-3' />
          {languagesFilter()}
          {teamPatientsFilter()}
        </>
      );
    } else if (filter == 'patient') {
      return (
        <>
          {showDateRange()}
          {!hideBmiFilter && renderBMIFilter()}
          <div className='my-3' />
          {genderFilter()}
          {patientAdminFilter()}
        </>
      );
    } else if (filter == 'orders') {
      return (
        <>
          {showDateRange()}
          {renderOrderStatusFilter()}
          {renderOrderTypeFilter()}
          {renderPriceRangeFilter()}
        </>
      );
    } else {
      return (
        <>
          {showDateRange()}
          {categoryFilter()}
          {showAutherFilter()}
          {tagsFilter()}
        </>
      );
    }
  };

  const renderModal = () => {
    return (
      <div className='filterModal'>
        <div
          className='offcanvas offcanvas-end'
          tabIndex='-1'
          id='offcanvasRight'
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='whiteBackground'>
            {filterHeader()}
            <div className='filterHeaderData'>{renderFilters()}</div>
            {renderFooter()}
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default FilterModal;